export const statusOption = [
    {
        label: '待领取',
        value: 0
    },
    {
        label: '已领取',
        value: 1
    },
    {
        label: '停止',
        value: 2
    },
    {
        label: '进行中',
        value: 3
    },
    {
        label: '领薪失败',
        value: 4
    }
]

// export const statusMap = {
//     0: '未领薪',
//     1: '已领薪',
//     2: '停止发薪',
//     3: '进行中',
//     4: '领薪失败'
// }

function createMap(options: any) {
    let map = {} as any
    options.forEach((i: any) => {
        map[i.value] = i.label
    })
    console.log(33333, map)
    return map
}

export const auditStatusOption = [
    {
        label: '待审核',
        value: 0
    },
    {
        label: '通过',
        value: 1
    },
    {
        label: '驳回',
        value: 2
    }
]

export const statusMap = createMap(statusOption)
export const auditStatusMap = createMap(auditStatusOption)
export const colorMap = {
    0: '#3D61F5',
    1: '#04CD5A',
    2: '#F6445F',
    3: '#E89E05',
    4: '#F6445F'
}
export const occupationStatusMap = {
    0: '待审核',
    1: '已通过',
    2: '已驳回',
    3: '已禁用'
}
