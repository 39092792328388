<template>
    <div class="drawer">
        <el-drawer ref="drawer" v-model="showDrawer" class="drawer" :with-header="false" :close-on-click-modal="true"
            :destroy-on-close="true" :append-to-body="false" direction="rtl" :size="650">
            <div class="close flex flex-ai-c pointer" @click="handleClose">
                <el-icon class="icon-close">
                    <DArrowRight />
                </el-icon>
            </div>
            <div class="content-box">
                <div class="head flex">
                    <el-image class="img-head" :src="userInfo.userHeadUrl" width="56" height="56">
                        <template #error>
                            <div class="image-slot1">
                                {{ lastWord(userInfo.userName) }}
                            </div>
                        </template>
                    </el-image>
                    <div>
                        <div><span class="fs-18 bold">{{ userInfo.userName }}</span><span v-if="userInfo.nickName">（备注名：{{
                            userInfo.nickName }}）</span></div>
                        <div class="flex mg-t-10 gap-20">
                            <div>ID：<span class="fc-grey">{{ userInfo.idCardNo }}</span></div>
                            <div>TEL：<span class="fc-grey">{{ userInfo.phone }}</span></div>
                        </div>
                    </div>
                </div>
                <div class="title-box mg-t-10">
                    具体信息
                </div>
                <div class="basic-box">
                    <div class="flex mg-t-10 gap-20">
                        <div>银行卡号：<span class="fc-grey">{{ userInfo.bankCardNo }}</span></div>
                        <div>紧急联系人：<span class="fc-grey">{{ userInfo.phone }}</span></div>
                    </div>
                    <!-- <div class="mg-t-10">
                        预支额度：<span class="fc-grey">100</span><span class="fc-primary mg-l-5 pointer" @click="showDialog=true">修改</span>
                    </div> -->
                    <div class="mg-t-10 flex">
                        <div>身份照片： </div>
                        <div v-if="userInfo.ownIdCardFront && userInfo.ownIdCardBack && userInfo.ownIdCardFront!=='1'">
                            <el-image :src="userInfo.ownIdCardFront" fit="contain" style="width: 64px; height: 64px" :preview-src-list="[userInfo.ownIdCardFront]">
                                <template #error>
                                    <div class="image-slot">

                                    </div>
                                </template>
                            </el-image>
                            <el-image class="mg-l-10" :src="userInfo.ownIdCardBack" fit="contain" style="width: 64px; height: 64px" :preview-src-list="[userInfo.ownIdCardBack]">
                                <template #error>
                                    <div class="image-slot">

                                    </div>
                                </template>
                            </el-image>
                        </div>
                        <div v-else>
                            --
                        </div>
                    </div>
                </div>
                <div class="title-box mg-t-10">
                    预支信息
                </div>
                <div class="flex flex-jc-sb mg-t-20">
                    <div class="flex flex-ai-c">
                        <span>筛选时间：</span>
                        <el-date-picker v-model="filterDate" type="date" clearable value-format="YYYY-MM-DD" placeholder="请选择"
                            size="small" @change="handleChange" />
                    </div>
                    <div style="width: 100px">
                        <el-select v-model="selectVal" style="width: 110px" clearable placeholder="请选择" size="small" @change="handleChange">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </div>
                </div>
                <div class="mg-t-20 process-box" :class="{'reset-height': (userInfo.ownIdCardFront || userInfo.ownIdCardBack) && userInfo.ownIdCardFront!=='1'}" @scroll="handleScroll($event)">
                    <template v-if="dataList.length">
                        <el-timeline v-for="(i, index) in dataList" :key="index">
                            <el-timeline-item :timestamp="i.payTime" placement="top">
                                <div v-if="i.isAdvance === 0" class="flex gap-15">
                                    <div class="btn style-1">发薪</div>
                                    <div class="flex-1 flex flex-jc-sb">
                                        <div>
                                            <div class="flex mg-b-10">
                                                <span class="fc-grey flex-no-shrink">所在企业：</span>
                                                <span>{{ i.serveCompanyName }}</span>
                                            </div>
                                            <div class="flex  mg-b-10">
                                                <span class="fc-grey flex-no-shrink">所在项目：</span>
                                                <span></span>
                                            </div>
                                            <div class="flex">
                                                <span class="fc-grey">正常发薪：</span>
                                                <span class="fc-pink">{{ i.payAmount }}元</span>
                                            </div>
                                        </div>
                                        <div :class="[`type-${i.status}`]">
                                            <span>&bull;</span><span class="mg-l-5">{{ statusName(i.status) }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="i.isAdvance === 1" class="flex gap-15">
                                    <div class="btn style-2">预支</div>
                                    <div class="flex-1 flex flex-jc-sb">
                                        <div>
                                            <div class="flex mg-b-10">
                                                <span class="fc-grey flex-no-shrink">所在企业：</span>
                                                <span>{{ i.serveCompanyName }}</span>
                                            </div>
                                            <div class="flex  mg-b-10">
                                                <span class="fc-grey flex-no-shrink">预支备注：</span>
                                                <span>{{ i.remark }}</span>
                                            </div>
                                            <div class="flex">
                                                <span class="fc-grey">预支申请：</span>
                                                <span class="fc-pink">{{ i.advanceAmount }}元</span>
                                            </div>
                                        </div>
                                        <div class="flex-no-shrink" :class="[`type-${i.status}`]">
                                            <span>&bull;</span><span class="mg-l-5">{{ statusName(i.status) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </template>
                    <template v-else>
                        <div class="text-center fs-gray">
                            暂无数据
                        </div>
                    </template>
                </div>
            </div>
        </el-drawer>
        <dialogModifyAmount v-model:show="showDialog" />
    </div>
</template>
<script>
import dialogModifyAmount from './dialog-modify-amount.vue';
import { advanceUserInfo, queryPaymentRecord } from '@/api/advance.ts';
import { statusOption } from '@/utils/map.ts';
export default {
    components: {
        dialogModifyAmount
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        userId: {
            type: [Number, String],
            default: ''
        },
        cardId: {
            type: [Number, String],
            default: ''
        }
    },
    data() {
        return {
            showDrawer: false,
            filterDate: '',
            selectVal: '',
            //  0:待领,1:已领,2:停止,3:进行中,4:领薪失败
            options: statusOption,
            // [
            //     {
            //         label: '全部',
            //         value: '',
            //     },
            //     {
            //         label: '待领取',
            //         value: 0,
            //     },
            //     {
            //         label: '已领取',
            //         value: 1,
            //     },
            //     {
            //         label: '停止发薪',
            //         value: 2,
            //     },
            //     {
            //         label: '进行中',
            //         value: 3,
            //     },
            //     {
            //         label: '领薪失败',
            //         value: 4,
            //     },
            // ],
            showDialog: false,
            userInfo: {},
            dataList: [],
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            }
        }
    },
    computed: {
        statusName() {
            return (val) => {
                const cur = this.options.find(i => i.value === val)
                return cur.label
            }
        },
        lastWord() {
            return (val) => {
                return val.charAt(val.length - 1)
            }
        }
    },
    watch: {
        show: {
            handler(newval) {
                this.showDrawer = newval
                if (newval) {
                    this.getInfo()
                    this.getData()
                }
            },
            immediate: true
        },
        showDrawer(newval) {
            this.$emit('update:show', newval)
        },
    },
    methods: {
        handleClose() {
            this.showDrawer = false
            // this.$refs.drawer.close()
        },
        getInfo() {
            advanceUserInfo({
                userId: this.userId,
            }).then(res => {
                this.userInfo = res
            })
        },
        getData() {
            const query = {
                idCardNo: this.cardId,
                queryDate: this.filterDate,
                status: this.selectVal,
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
            }
            console.log(1111, query);
            queryPaymentRecord(query).then(res => {
                if (this.pageInfo.pageNum === 1) {
                    this.dataList = res.list
                } else {
                    this.dataList = this.dataList.concat(res.list)
                }
                this.pageInfo.total = res.totalCount
            })
        },
        handleChange() {
            this.pageInfo.pageNum = 1
            this.getData()
        },
        handleScroll(ev) {
            const el = ev.target
            if (el.clientHeight + el.scrollTop >= el.scrollHeight && (this.pageInfo.pageNum * this.pageInfo.pageSize < this.pageInfo.total)) {
                this.pageInfo.pageNum++
                this.getData()
            }
        },
    }
}
</script>
<style lang="stylus" scoped>
.close
    position absolute
    width 15px
    height 40px
    background #fff
    border-radius 4px 0 0 4px
    left -7px
    top 50%
    transform translate(-50%)
:deep().el-drawer
    overflow initial !important
.icon-close
    margin-left 10px
.img-head
    width 56px
    height 56px
    border-radius 4px
    margin-right 14px
.fs-22
    font-size 22px
.fc-grey
    color #767676
.mg-t-10
    margin-top 10px
.gap-20
    gap 20px
.mg-t-24
    margin-top 24px
.title-box
    background #F7F8FC
    border-radius 4px
    padding 10px 16px
    font-size 14px
.basic-box
    padding: 0 16px
:deep().el-timeline-item__timestamp.is-top
    position absolute
    left -140px
    top -3px
:deep().el-timeline
    margin-inline-start 100px
.process-box
    padding-top 10px
    max-height calc(100vh - 335px)
    overflow auto
.reset-height
    max-height calc(100vh - 390px)
.btn
    width 56px
    height 22px
    line-height 22px
    font-size 14px
    border 3px
    text-align center
.style-1
    color #00D679
    background rgba(200, 255, 231, 0.3)
.style-2
    color #0256FF
    background rgba(127, 158, 253, 0.3)
.gap-15
    gap 15px
.type-0
    color #0256FF//#00D679
.type-1
    color #00D679//#EA0000
.type-2
    color #EA0000
.type-3
    color #FFC768
.type-4
    color #EA0000
.fc-pink
    color #FF7E68
.image-slot1
    height 100%
    background #e8f3fe
    color #1989fa
    text-align center
    line-height 56px
    font-size 26px
    font-weight bold
</style>