
import { defineComponent } from 'vue'
export default defineComponent({
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogVisible: false,
            num: ''
        }
    },
    watch: {
        show(newval) {
            this.dialogVisible = newval
        },
        dialogVisible(newval) {
            this.$emit('update:show', newval)
        }
    },
    methods: {
        handleClose() {
            this.dialogVisible = false
        }
    }
})
