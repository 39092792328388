
import { defineComponent } from 'vue'
import { cloneDeep } from 'lodash'
import { Check } from '@element-plus/icons'
export default defineComponent({
  components: {
  },
  props: {
    searchList: {
      type: Object,
      default() {
        return {}
      }
    },
    buttonList: {
      type: Array,
      default() {
        return []
      }
    },
    flex: {
      type: Boolean,
      default: false
    },
    autoSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchData: [],
      timer: null as any
    }
  },
  watch: {
    searchList: {
      handler(newval) {
        this.searchData = cloneDeep(newval)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleSearch() {
      this.$emit('update:searchList', this.searchData)
      this.$emit('doSearch')
    },
    handleReset() {
      for (const key in this.searchData) {
        (this.searchData[key] as any).value = (this.searchData[key] as any).defaultValue
      }
      this.$emit('update:searchList', this.searchData)
      this.$emit('doSearch')
    },
    handleChange(item: any) {
        item.handleChange && item.handleChange(item)
            this.handleSearch()
    },
    handleInputChange(item: any) {
      console.log(212121, item)
      if (item.value) {
        item.value = item.value.replace(/\t/, '').trim()
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        item.handleInputChange && item.handleInputChange(item)
        this.handleSearch()
      }, 300)
    }
    // handleDisabledDate(date: any, i: any) {
    //   console.log(111, i.disabledDate);
    //   if (i.disabledDate) {
    //     return i.disabledDate(date, i)
    //   }
    //   return true
    // }
  }
})
