
import { defineComponent } from 'vue'
import { queryAdvanceApplyDetail } from '@/api/advance.ts';
export default defineComponent({
    props: {
        show: {
            type: Boolean,
            default: false
        },
        id: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            dialogVisible: false,
            advanceData: {} as any
        }
    },
    watch: {
        show(newval) {
            this.dialogVisible = newval
        },
        dialogVisible(newval) {
            this.$emit('update:show', newval)
        },
        id (newval) {
            if(newval) {
                this.getData()
            }
        }
    },
    methods: {
        handleClose() {
            this.dialogVisible = false
        },
        getData() {
            queryAdvanceApplyDetail({
                id: this.id
            }).then(res => {
                console.log(444444, res);
                this.advanceData = res
            })
        }
    }
})
